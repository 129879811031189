import { Cookies } from 'react-cookie';
import moment from 'moment';

export const cookies = new Cookies();

export const cookieKey = {
  pass: 'pass',
};

export const getPass: () => string = () => cookies.get(cookieKey.pass);
export const setPass = (pass: string): void => {
  console.log(`[setPass]: ${pass}`);
  cookies.set(cookieKey.pass, pass, {
    path: '/',
    expires: moment().add(1, 'year').toDate(),
  });
};
