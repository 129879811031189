import React, { useState, useEffect, useCallback, memo } from 'react';
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  MiniMap,
  Controls,
  Handle,
  Position,
  Background,
} from 'reactflow';
import { SmartBezierEdge } from '@tisoap/react-flow-smart-edge';
import 'reactflow/dist/style.css';
import './index.css';
import { io } from 'socket.io-client';
import _ from 'lodash';
import BigNumber from 'bignumber.js';
import { setRootLoading } from '../..';
import { fromBech32, toBech32 } from '../../tools/bech32';
import mojaik from '../../assets/images/mojaik.png';
import IframeViewer from '../IframeViewer';
import { PageContainer, Container, Menu, PageContent } from './styles';
import { erc20_list, tx, typeAddr, erc_20, internal_transaction } from './type';
import { getPass, setPass } from '../../tools/function';

const HOST =
  global.location.hostname === 'localhost'
    ? 'http://localhost:3001'
    : 'https://proxy.pleizz.com';

function getTypeInput(target: string): typeAddr {
  const v = target.trim().toLowerCase();

  if (`${+v}` === v && +v > 0) {
    // is block number
    return 'block';
  }

  if (v.length !== 66 && v.length !== 42) {
    return 'unknown';
  }
  if (v.length === 42 && /^0x[a-f0-9]+$/.test(v)) {
    return 'address-hex';
  }

  if (v.length === 42 && v.slice(0, 4) === 'fee1') {
    return 'address-bech32';
  }

  if (v.length === 66 && v[0] === '0' && v[1] === 'x') {
    // is block hash or tx hash
    return 'unsupported';
  }
  return 'unknown';
}

const explorerSocket = io('wss://ws.explorer.timestope.com', {
  transports: ['websocket'],
  forceNew: true,
  reconnection: true,
  reconnectionAttempts: Infinity,
});

const LeftRightNode = memo(({ data, isConnectable }: any) => {
  return (
    <>
      <div style={{ backgroundColor: 'gray', borderRadius: 15, padding: 10 }}>
        wallet: <strong>{data.label}</strong>
      </div>
      <Handle
        type="target"
        position={Position.Right}
        id="right"
        style={{ background: '#555' }}
        isConnectable={isConnectable}
      />
      <Handle
        type="target"
        position={Position.Left}
        id="left"
        style={{ background: '#555' }}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Right}
        id="right"
        style={{ background: '#555' }}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Left}
        id="left"
        style={{ background: '#555' }}
        isConnectable={isConnectable}
      />
    </>
  );
});

const TopBottomNode = memo(({ data, isConnectable }: any) => {
  return (
    <>
      <div style={{ backgroundColor: 'gray', borderRadius: 15, padding: 10 }}>
        wallet: <strong>{data.label}</strong>
      </div>
      <Handle
        type="target"
        position={Position.Top}
        id="top"
        style={{ background: '#555' }}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="bottom"
        style={{ background: '#555' }}
        isConnectable={isConnectable}
      />
    </>
  );
});

const connectionLineStyle = { stroke: '#fff' };
const snapGrid: [number, number] = [20, 20];
const nodeTypes = {
  updown: TopBottomNode,
  leftright: LeftRightNode,
};
const edgeTypes = {
  smart: SmartBezierEdge,
};

const defaultViewport = { x: 0, y: 0, zoom: 1.5 };

function parseInternalFrc20(abi: string) {
  // internal transaction parse
  const digit = 6;
  BigNumber.config({ DECIMAL_PLACES: digit });
  const spl = abi.slice(10).match(/.{1,64}/g);
  if (!spl) {
    throw new Error(`failed to decrypt abi: ${abi}`);
  }

  const txes = {
    wallet: `0x${spl[0].slice(-40)}`,
    amount: new BigNumber(spl[1], 16),
  };

  return txes;
}

const CustomNodeFlow = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState([
    {
      id: '1',
      type: 'input',
      data: {
        label: (
          <>
            Welcome to <strong>FeeChain Flow Chart!</strong>
          </>
        ),
        url: 'https://www.pleizz.com/',
      },
      position: { x: 0, y: 0 },
    },
  ]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const [iframeActive, setIframeActive] = useState(false);
  const [iframeURL, setIframeURL] = useState('https://www.pleizz.com');
  const [AllERC20, setAllERC20] = useState<erc20_list[]>([
    {
      address: '',
      circulatingSupply: '',
      decimals: 0,
      holders: '',
      lastUpdateBlockNumber: '',
      name: '',
      symbol: '',
      totalSupply: '',
      transactionCount: '',
    },
  ]);
  const [txOrg, setTxOrg] = useState<{
    transaction: tx[];
    internal: internal_transaction[];
    erc: erc_20[];
  }>({ transaction: [], internal: [], erc: [] });

  const handleOnClickBox = (urlIframe?: string): void => {
    if (urlIframe === iframeURL && iframeActive) {
      return setIframeActive(false);
    }
    setIframeActive(true);
    if (urlIframe) {
      setIframeURL(urlIframe || 'https://www.pleizz.com/not-found');
    }
    // setTimeout(
    //   () =>
    //     (document.getElementsByClassName(
    //       'react-flow__controls-button react-flow__controls-fitview',
    //     )[0] as HTMLElement).click(),
    //   500,
    // );
  };

  const getParameter = (key: string) => {
    return new URLSearchParams(global.location.search).get(key);
  };

  useEffect(() => {
    async function setERC() {
      const ERC20 = (await getFromSocket('getAllERC20', [])) as erc20_list[];
      setAllERC20(ERC20);
      console.log(`got ${ERC20.length} of ERC20 tokens.`);
    }
    setERC();
  }, []);

  useEffect(() => {
    async function queryString() {
      if (AllERC20.length === 1) {
        return;
      }
      const untilErcLoaded = () =>
        new Promise((resolve) => {
          const checkExist = () => {
            if (AllERC20.length) {
              resolve('ok');
            } else {
              console.log('notExist');
              requestAnimationFrame(checkExist);
            }
          };
          checkExist();
        });

      await untilErcLoaded();
      const target = getParameter('target');
      target && onClickStart(target);
    }
    queryString();
  }, [AllERC20]);

  function getFromSocket(method: string, data: any[]) {
    return new Promise((resolve, reject) =>
      explorerSocket.emit(
        method,
        data,
        ({
          event,
          payload: orgPayload,
        }: {
          event: string;
          payload: string;
        }) => {
          try {
            const payload = JSON.parse(orgPayload);
            if (event === 'Response') {
              resolve(payload);
            }
          } catch (e) {
            reject(e);
          }
        },
      ),
    );
  }

  async function onClickStart(preTarget = '') {
    async function start() {
      try {
        const target =
          preTarget ||
          (
            (document.getElementById('target_id') as HTMLInputElement)?.value ||
            ''
          )
            .trim()
            .toLowerCase();
        if (!target) {
          return alert('no-target');
        }
        const type = getTypeInput(target);
        let address = '';
        switch (type) {
          case 'block':
          case 'unknown':
          case 'unsupported': {
            alert(`cannot try ${type}`);
            break;
          }
          case 'address-hex': {
            address = toBech32(target);
          }
          // eslint-disable-next-line no-fallthrough
          case 'address-bech32': {
            address = fromBech32(address || target);

            if (AllERC20.map((v) => v.address).includes(address)) {
              alert(
                'notice: access to history of frc20 token may cause lots of lags and sometimes incorrect.',
              );
              // return alert('cannot access history of frc20 token.');
            }

            const erc20_lst = AllERC20.map((v) => v.address);

            const main_tx = (await getFromSocket(
              'getRelatedTransactionsByType',
              [
                0,
                address,
                'transaction',
                {
                  offset: 0,
                  limit: 5000,
                  orderBy: 'block_number',
                  orderDirection: 'desc',
                  filters: [
                    {
                      type: 'gte',
                      property: 'block_number',
                      value: 0,
                    },
                  ],
                },
              ],
            )) as tx[];
            main_tx.length > 4999 &&
              alert(
                '최대 5천 개의 내역을 불러올 수 있으나 5천개 이상의 transaction이 있는 것 같습니다.\n이 경우 정확하지 않을 수 있습니다.',
              );
            const internal_tx = (await getFromSocket(
              'getRelatedTransactionsByType',
              [
                0,
                address,
                'internal_transaction',
                {
                  offset: 0,
                  limit: 5000,
                  orderBy: 'block_number',
                  orderDirection: 'desc',
                  filters: [
                    {
                      type: 'gte',
                      property: 'block_number',
                      value: 0,
                    },
                  ],
                },
              ],
            )) as internal_transaction[];
            internal_tx.length > 4999 &&
              alert(
                '최대 5천 개의 내역을 불러올 수 있으나 5천개 이상의 internal_transaction이 있는 것 같습니다.\n이 경우 정확하지 않을 수 있습니다.',
              );
            const erc_tx = (await getFromSocket(
              'getRelatedTransactionsByType',
              [
                0,
                address,
                'erc20',
                {
                  offset: 0,
                  limit: 5000,
                  orderBy: 'block_number',
                  orderDirection: 'desc',
                  filters: [
                    {
                      type: 'gte',
                      property: 'block_number',
                      value: 0,
                    },
                  ],
                },
              ],
            )) as erc_20[];
            erc_tx.length > 4999 &&
              alert(
                '최대 5천 개의 내역을 불러올 수 있으나 5천개 이상의 erc20이 있는 것 같습니다.\n이 경우 정확하지 않을 수 있습니다.',
              );

            const txState = {
              transaction: main_tx,
              internal: internal_tx,
              erc: erc_tx,
            };

            setTxOrg(txState);

            /*

            transaction 파싱 에러 발생 메모

            tx: https://explorer.timestope.com/tx/0x507c25ad1e55a1861e4f16e91466769c42585f2d8b756eca0d82542302c17859

            frc20 주소로 보내는 경우
            if (!erc20_lst.includes(v.to))
            여기에 걸리지 않아 abi parse를 하게 되는데
            이때 input이 0x (빈 데이터)기 때문에 파싱 에러로 인해 해당 함수에서 에러를 반환하는 경우

            해결: if문에 v.input === '0x0 추가

            */

            const transaction = main_tx.map((v) => {
              if (!erc20_lst.includes(v.to) || v.input === '0x') {
                return {
                  ...v,
                  value: BigNumber(v.value)
                    .dividedBy(10 ** 18)
                    .toString(),
                  symbol: 'fee',
                };
              }
              const parse = parseInternalFrc20(v.input);
              return {
                ...v,
                to: parse.wallet,
                value: parse.amount
                  .dividedBy(
                    10 ** AllERC20.find((v1) => v1.address === v.to).decimals,
                  )
                  .toString(),
                symbol: AllERC20.find((v1) => v1.address === v.to).symbol,
              };
            });
            // console.log('transaction', transaction[0]);

            const int_tx = internal_tx.map((v) => {
              if (!erc20_lst.includes(v.to) || v.input === '0x') {
                return {
                  ...v,
                  hash: v.transactionHash,
                  value: BigNumber(v.value)
                    .dividedBy(10 ** 18)
                    .toString(),
                  symbol: 'fee',
                };
              }
              const parse = parseInternalFrc20(v.input);
              return {
                ...v,
                hash: v.transactionHash,
                to: parse.wallet,
                value: parse.amount
                  .dividedBy(
                    10 ** AllERC20.find((v1) => v1.address === v.to).decimals,
                  )
                  .toString(),
                symbol: AllERC20.find((v1) => v1.address === v.to).symbol,
              };
            });
            // console.log('internal', int_tx[0]);

            const erc20 = erc_tx.map((v) => ({
              ...v,
              hash: v.transactionHash,
              value: BigNumber(v.value)
                .dividedBy(
                  10 **
                    AllERC20.find((v1) => v1.address === v.address).decimals,
                )
                .toString(),
              symbol: AllERC20.find((v1) => v1.address === v.address).symbol,
            }));
            // console.log('erc20', erc20[0]);

            const api = [...transaction, ...int_tx, ...erc20];

            const data = _.uniqBy(api, 'hash');
            // setTxes(data);

            const addr = [
              ...new Set(data.map((v) => [v.from, v.to]).flat()),
            ].filter((v) => v !== address);

            const radius = Math.max(
              500,
              Math.max(global.screen.width, global.screen.height) / 4,
            ); // Math.min(500, 50 * addr.length);

            const link = data
              .map((v) => ({
                id: v.hash,
                source: v.from,
                target: v.to,
                animated: true,
                labelStyle: { fontWeight: 700, width: 'fit-content' },
                label: `${v.value}`,
                symbol: v.symbol,
                value: v.value,
                // sourceHandle: v.from === address ? 'right' : 'left',
                // targetHandle: v.from === address ? 'left' : 'right',
                // type: 'smart',
              }))
              .reduce((acc, cur) => {
                const find = acc.find(
                  (v) =>
                    (v.source === cur.source && v.target === cur.target) ||
                    (v.source === cur.target && v.target === cur.source),
                );
                const received = address === cur.target;
                if (find) {
                  if (!find.list[cur.symbol]) {
                    find.list[cur.symbol] = {
                      plus: '0',
                      minus: '0',
                      total: '0',
                    };
                  }
                  find.list[cur.symbol][received ? 'plus' : 'minus'] =
                    BigNumber(
                      find.list[cur.symbol][received ? 'plus' : 'minus'],
                    )
                      .plus(cur.value)
                      .toString();

                  find.list[cur.symbol].total = BigNumber(
                    find.list[cur.symbol].total,
                  )
                    [received ? 'plus' : 'minus'](cur.value)
                    .toString();
                } else
                  acc.push({
                    ...cur,
                    list: {
                      [cur.symbol]: {
                        total: (received ? '' : '-') + cur.value,
                        plus: received ? `${cur.value}` : '0',
                        minus: received ? '0' : cur.value,
                      },
                    },
                  });

                return acc;
              }, [])
              .map((v) => ({
                ...v,
                ...determineTargetSource(v.source, v.target, address, v.list),
                label: Object.keys(v.list)
                  .map((v1, i1) =>
                    v.list[v1].plus && v.list[v1].minus === '0' // +만
                      ? `+${numberWithCommas(v.list[v1].plus)} ${v1}`
                      : v.list[v1].plus === '0' && v.list[v1].minus // -만
                      ? `-${numberWithCommas(v.list[v1].minus)} ${v1}`
                      : `(+${numberWithCommas(
                          v.list[v1].plus,
                        )} -${numberWithCommas(v.list[v1].minus)}) => ${
                          BigNumber(v.list[v1].total).isGreaterThan(0)
                            ? '+'
                            : ''
                        }${numberWithCommas(v.list[v1].total)} ${v1}`,
                  )
                  .join(' | '),
              }));

            /*

function duplSumV2(logs: fee_chain_history_db[]) {
  return logs.reduce(
    (accumulator: fee_chain_history_db[], cur: fee_chain_history_db) => {
      const found = accumulator.find(function (elem) {
        return elem.to == cur.to;
      });
      if (found) {
        found.amount = found.amount.plus(cur.amount);
      } else accumulator.push(cur);
      return accumulator;
    },
    [],
  );
}
              */

            // const valueSort = link.map((v) => v);

            const addrALL = addr.map((v) => ({
              wallet: v,
              total: link.find((v1) => v1.notTarget === v)?.totalFee || 0,
            }));

            const receivedAddr = addrALL.filter((v) => v.total >= 0);
            const sentAddr = addrALL.filter((v) => v.total < 0);

            const pass =
              (
                (document.getElementById('pass_id') as HTMLInputElement)
                  ?.value || ''
              ).trim() ||
              getPass() ||
              '';

            const resp = await fetch(`${HOST}/www/apply-wallet-info`, {
              method: 'POST',
              body: JSON.stringify({
                wallet: [
                  ...receivedAddr.map((v) => v.wallet),
                  ...sentAddr.map((v) => v.wallet),
                  address,
                ],
                pass,
              }),
              headers: { 'Content-Type': 'application/json' },
            });

            const body = await resp.json();

            if (!body?.success) {
              return alert('failed to load wallet-info');
            }

            setPass(pass);

            const info_addr = body.data as string[];

            const receivedNode = receivedAddr.map((v, i) => ({
              id: v.wallet,
              data: {
                label: `${v.wallet}${info_addr[i] ? ` (${info_addr[i]})` : ''}`,
              },
              position: {
                x:
                  Math.cos((i * Math.PI) / (receivedAddr.length - 1)) *
                    radius || 0, // NaN인 경우 0으로 처리
                y:
                  -1 *
                  (Math.abs(
                    Math.sin((i * Math.PI) / (receivedAddr.length - 1)) *
                      radius || 0,
                  ) +
                    150),
              },
              style: { width: 'fit-content' },
              // type: 'updown',
            }));

            const sentNode = sentAddr.map((v, i) => ({
              id: v.wallet,
              data: {
                label: `${v.wallet}${
                  info_addr[receivedAddr.length + i]
                    ? ` (${info_addr[receivedAddr.length + i]})`
                    : ''
                }`,
              },
              position: {
                x:
                  Math.cos((i * Math.PI) / (sentAddr.length - 1)) * radius || 0,
                y:
                  Math.abs(
                    Math.sin((i * Math.PI) / (sentAddr.length - 1)) * radius ||
                      0,
                  ) + 150,
              },
              style: { width: 'fit-content' },
              // type: 'updown',
            }));

            const addrNode = [
              ...receivedNode,
              ...sentNode,
              {
                id: address,
                data: {
                  label: `[타깃] ${address}${
                    info_addr[info_addr.length - 1]
                      ? ` (${info_addr[info_addr.length - 1]})`
                      : ''
                  }`,
                },
                position: { x: 0, y: 0 },
                style: { width: 'fit-content' },
                // type: 'leftright',
              },
            ];

            const node = _.uniqBy(addrNode, 'id');
            const edge = _.uniqBy(link, 'id');
            // console.log({ node, edge });
            setNodes(node);
            setEdges(edge);
            const url = new URL(global.window.location.toString());
            url.searchParams.set('target', target);
            window.history.pushState(null, '', url.toString());

            break;
          }
          default: {
            alert(`not-defined ${type}`);
            break;
          }
        }
      } catch (e) {
        console.error('failed to start', e);
      }
    }
    setRootLoading(true);
    await start();
    setRootLoading(false);
  }

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge({ ...params, animated: true, style: { stroke: '#fff' } }, eds),
      ),
    [],
  );

  // const removeAd = () =>
  //   new Promise((resolve) => {
  //     const className = '.react-flow__attribution';
  //     const getElement = () => {
  //       const element = document.getElementsByClassName(className);
  //       if (element) {
  //         document.querySelectorAll(className).forEach((el: HTMLElement) => {
  //           el.style.display = 'none';
  //         });
  //         resolve(element);
  //       } else {
  //         requestAnimationFrame(getElement);
  //       }
  //     };
  //     getElement();
  //   });

  // useEffect(() => {
  //   removeAd();
  // }, []);

  return (
    <PageContainer>
      <Container>
        <Menu>
          <img width="50" src={mojaik} alt="Click Funnels" />
          <h1>FeeChain Tracker</h1>
        </Menu>
        <div>
          <span style={{ color: 'white' }}>This program was forked from </span>
          <a
            target="_blank"
            href="https://github.com/mauricioblum/react-sequence-draggable-example"
            rel="noreferrer"
          >
            react-sequence-draggable-example
          </a>
        </div>
        <div>
          <span style={{ color: 'white' }}>
            Click once to see explorer (extended)
          </span>
          <br />
          <span style={{ color: 'white' }}>
            Double click to open new tracker
          </span>
        </div>
        <input id="target_id" placeholder="target address" />
        <input id="pass_id" placeholder="auth pass" defaultValue={getPass()} />
        <button type="button" onClick={() => onClickStart()}>
          시작하기
        </button>
        <PageContent>
          <div style={{ width: '100%', height: '100%' }}>
            <ReactFlow
              nodes={nodes}
              nodeOrigin={[0.5, 0.5]}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              style={{}}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              connectionLineStyle={connectionLineStyle}
              snapToGrid
              snapGrid={snapGrid}
              defaultViewport={defaultViewport}
              fitView
              attributionPosition="bottom-left"
              proOptions={{ hideAttribution: true }}
              onNodeDoubleClick={(_e, node) =>
                window.open(
                  `${window.location.hostname}/?target=${node.id}`,
                  '_blank',
                )
              }
              onNodeClick={(_e, node) =>
                handleOnClickBox(
                  `https://explorer.timestope.com/address/${node.id}`,
                )
              }
            >
              <MiniMap
                nodeStrokeColor="#ff0ff0"
                nodeColor="#ff0ff0"
                // nodeStrokeColor={(n) => {
                // if (n.type === 'input') return '#0041d0';
                // // if (n.type === 'selectorNode') return bgColor;
                // if (n.type === 'output') return '#ff0072';
                // }}
                // nodeColor={(n) => {
                //   // if (n.type === 'selectorNode') return bgColor;
                //   return '#fff';
                // }}
              />
              <Background color="#aaa" gap={16} />
              <Controls />
            </ReactFlow>
          </div>
        </PageContent>
        <HotWallets />
      </Container>
      <IframeViewer animate={iframeActive} iframeURL={iframeURL} />
    </PageContainer>
  );
};

export default CustomNodeFlow;

function numberWithCommas(x) {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
}

function HotWallets() {
  const [wallet, setWallet] = useState<
    { feeWallet: string; ethWallet: string; info: string }[]
  >([]);

  useEffect(() => {
    async function getWallets() {
      const resp = await fetch(`${HOST}/www/get-wallet-info`, {
        method: 'POST',
      });
      const body = await resp.json();
      if (!body?.success) {
        return alert('failed to load hot wallet.');
      }

      setWallet(body.data);
    }
    getWallets();
  }, []);

  return (
    <>
      {wallet.length ? (
        <details open={false}>
          <summary>지갑 보기</summary>
          {wallet.map((v) => (
            <div key={v.ethWallet}>
              <a href={`./?target=${v.ethWallet}`}>{v.info}</a>
              <br />
            </div>
          ))}
        </details>
      ) : (
        'Loading Wallets...'
      )}
    </>
  );
}

function getMultiplyBySymbol(org_symbol: string) {
  const symbol = org_symbol.toLowerCase();
  let multi = 0; // Fee를 기준으로
  switch (symbol) {
    case 'xtime': {
      multi = 1 / 3;
      break;
    }
    case 'fee': {
      multi = 1;
      break;
    }
    case 'usdm': {
      multi = 769.23;
      break;
    }
    case 'krwm': {
      multi = (1 / 1430) * 769.23; // PreICO 무시, ICO 기준 환율
      break;
    }
    default: {
      multi = 0;
      console.error(`symbol [${org_symbol}] is not defined!`);
      break;
    }
  }
  return multi;
}

function determineTargetSource(
  org_source: string,
  org_target: string,
  address: string,
  list: {
    [key: string]: {
      total: string;
      plus: string;
      minus: string;
    };
  },
) {
  const keys = Object.keys(list);
  const totalFee = Object.values(list).reduce(
    (prev, cur, i) =>
      prev + getMultiplyBySymbol(keys[i]) * parseInt(cur.total, 10),
    0,
  );
  const notTarget = org_source === address ? org_target : org_source;
  const source = totalFee >= 0 ? notTarget : address;
  const target = totalFee >= 0 ? address : notTarget;
  return {
    source,
    target,
    notTarget,
    totalFee,
    style: { stroke: totalFee >= 0 ? 'red' : 'blue' },
  };
}
