import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import App from './App';

import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

setRootLoading(false);

export function setRootLoading(load: boolean) {
  try {
    load ? $('#root_loading').show() : $('#root_loading').hide();
  } catch (e) {
    console.error(`failed to setLoad ${load}: ${e.message}`);
  }
}
